<script context="module">
    import Notification from './Notification.svelte';

    let container;

    export function notify(options) {

        if (typeof options === 'string' || options instanceof String) {
            options = {
                "message": options
            };
        }

        const notif = new Notification({
            "target": container,
            "props": options,
            "intro": true
        });

        notif.$on('close', () => {
            notif.$destroy();
        });

        return notif;
    }

    document.addEventListener('DOMContentLoaded', () => {
        container = document.createElement('div');
        container.classList.add('notify');
        document.body.appendChild(container);
    });
</script>

<style type="text/scss">
    :global(.notify) {
        position: fixed;
        display: flex;
        z-index: 1000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 2em;
        overflow: hidden;
        pointer-events: none;
        /* column, column-reverse */
        flex-direction: column-reverse;

        :global(.notification) {
            display: inline-flex;
            pointer-events: auto;
            max-width: 600px;
            margin: 0.5em 0;
            opacity: 0.9;
            /* flex-start, center, flex-end */
            align-self: flex-end;
        }
    }
</style>