<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import tippy from 'tippy.js';

    export let currentTime = 0, duration = 0;

    let element, tooltip;

    const dispatch = createEventDispatcher();

    let pc = 0;

    $: pc = ((duration > 0) ? (100 * currentTime / duration) : 0);

    function calc(x) {
        let r = element.getBoundingClientRect();
        return (x - r.left) / r.width;
    }

    function handleMouseMove(event) {
        let p = calc(event.x);
        let time = formatTime(p * duration);
        tooltip.setContent(time);
    }

    function handleClick(event) {
        let p = calc(event.x);
        let t = p * duration;
        dispatch('timeupdate', {
            "time": t,
            "progress": p
        });
    }

    function formatTime(s) {
        let date = new Date(1000 * s);
        return date.getUTCHours() > 0 ?
            date.toISOString().substr(11, 8) :
            date.toISOString().substr(14, 5);
    }

    onMount(async () => {
        tooltip = tippy(element, {
            "content": "",
            "followCursor": "horizontal",
            "arrow": true,
            "theme": "light",
            "size": "large",
            "zIndex": 9999,
            "ignoreAttributes": true,
            "touch": true,
            "onShow": () => {
                if (duration == 0) {
                    // Do not show
                    return false;
                }
            }
        });
    });
</script>

<style>
    .media-progress__outer {
        position: relative;
        display: block;
        background: dimgray;
        height: 100%;
        pointer-events: auto;
        cursor: pointer;
    }
    .media-progress__inner {
        background: white;
        width: 0;
        height: 100%;
    }
</style>

<div class="media-progress__outer" bind:this="{element}"
    on:mousemove="{handleMouseMove}"
    on:click="{handleClick}">
    <div class="media-progress__inner" style="width:{pc}%"></div>
</div>