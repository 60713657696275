<script>
    import { createEventDispatcher } from 'svelte';
    import './svg/line-angle-right.svg';

    let q = '';

    const dispatch = createEventDispatcher();

    function handleSubmit() {
        dispatch('search', {
            "q": q
        });
    }
</script>

<form on:submit|preventDefault="{handleSubmit}">
    <div class="field has-addons">
        <p class="control is-expanded">
            <input bind:value="{q}" class="input is-large" type="search" placeholder="Search...">
        </p>
        <p class="control">
            <button type="submit" class="button is-large">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#line-angle-right"/>
                <svg>
            </button>
        </p>
    </div>
</form>