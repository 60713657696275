import zhDic from './json/zh.json';
import pinyin from 'pinyin';

let zhDicLongestWord = Math.max(...Object.keys(zhDic).map(k => k.length));

function findLongestWord(s) {
    if (s.substring(0, 1).match(/[\u3400-\u9FBF]/)) {
        for (let l = zhDicLongestWord; l > 0; l--) {
            let w = s.substring(0, l);
            if (zhDic.hasOwnProperty(w)) {
                let word = {
                    "definition": zhDic[w][1],
                    "level": zhDic[w][2]
                };
                word.text = w;
                word.label = pinyin(w).join('');
                return word;
            }
        }
        let w = s.substring(0, 1);
        return {
            "text": w,
            "label": pinyin(w).join(''),
            "definition": null,
            "level": 0
        };
    } else {
        // Ignore non-chinese characters
        let l = 2;
        while (l <= s.length && !s.substring(l - 1, l).match(/[\u3400-\u9FBF]/)) {
            l++;
        }
        let w = s.substring(0, l - 1);
        return {
            "text": w,
            "label": null,
            "definition": null,
            "level": 0
        };
    }
}

export function segmentText(s, id = '') {
    let result = [];
    while (s.length > 0) {
        let word = findLongestWord(s);
        word.id = id + 'w' + (result.length + 1);
        result.push(word);
        s = s.substring(word.text.length);
    }
    return result;
}