<script>
    import { createEventDispatcher } from 'svelte';

    export let message = '';
    export let active = true;
    export let closable = true;
    export let type = '';
    export let closeOnClick = false;

    const dispatch = createEventDispatcher();

    function handleClose() {
        active = false;
        dispatch('close');
    }

    function handleClick() {
        dispatch('click');
        if (closeOnClick) {
            handleClose();
        }
    }
</script>

{#if active}
    <div class="notification {type}" on:click="{handleClick}">
        {#if closable}
            <button class="delete" on:click="{handleClose}"/>
        {/if}
        {#if message}
            <div class="content">
                {@html message}
            </div>
        {/if}
    </div>
{/if}