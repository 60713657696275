<script>
    import { createEventDispatcher } from 'svelte';
    import axios from 'axios';

    export let videoId = null, lang = null;

    let langOptions = [], isLoading = false;

    const dispatch = createEventDispatcher();

    $: load(videoId);

    export function load(videoId) {

        let options = {
            "params": {
                "type": "list",
                "v": videoId,
                "hl": "en"
            },
            "responseType": "document"
        };

        langOptions = [];
        isLoading = true;

        axios.get('https://www.youtube.com/api/timedtext', options)
            .then(response => {
                let nodes = Array.from(response.data.documentElement.childNodes);
                langOptions = nodes.map(node => {
                    return {
                        "code": node.getAttribute('lang_code'),
                        "name": node.getAttribute('lang_translated')
                    };
                });
                dispatch('ready', {
                    "langOptions": langOptions
                });
            })
            .catch(event => dispatch('error', event))
            .finally(() => {
                isLoading = false;
            });
    }

	function handleInput(event) {
		dispatch('change', {
            "lang": event.target.value
        });
	}
</script>

<div class="field has-addons has-addons-centered">
    <p class="control">
        <span class="select" class:is-loading="{isLoading}">
            <select on:input="{handleInput}" placeholder="Language">
                <option disabled selected="{lang == null}">Language</option>
                {#each langOptions as option}
                    <option value="{option.code}" selected="{lang == option.code}">
                        {option.name}
                    </option>
                {/each}
            </select>
        </span>
    </p>
</div>