<script>
    import { createEventDispatcher } from 'svelte';
    import { slide } from 'svelte/transition';
    import MediaProgress from './MediaProgress.svelte';
    import './svg/play.svg';
    import './svg/pause.svg';
    import './svg/step-backward.svg';
    import './svg/step-forward.svg';
    import './svg/combine-left-right.svg';
    import './svg/sound-medium.svg';
    import './svg/sound-off.svg';
    import './svg/line-angle-up.svg';
    import './svg/line-angle-down.svg';
    import './svg/search.svg';

    export let currentTime = 0,
        duration = 0,
        playbackRate = 1,
        offset = 0,
        volume = 100,
        muted = false,
        playing = false,
        repeating = false;

    let element, showSettings = false;

    const dispatch = createEventDispatcher();

    function handleVolumeClick() {
        volume = 1;
        muted = !muted;
        dispatch('volumechange', {
            "volume": volume,
            "muted": muted
        });
    }

    function handlePlaybackRateInput(event) {
        playbackRate = parseFloat(event.target.value);
    }

    function handlePlaybackRateChange(event) {
        dispatch('ratechange', {
            "playbackRate": playbackRate
        });
    }

    function handleOffsetChange(event) {
        offset = parseFloat(event.target.value);
        dispatch('offsetchange', {
            "offset": offset
        });
    }
</script>

<style type="text/scss">
    .media-controls__button {
        cursor: pointer;
        display: inline-block;
        margin: 0.8rem;
        width: 2rem;
        height: 2rem;

        svg {
            fill: white;
            width: 2rem;
            height: 2rem;
        }

        &:hover svg {
            filter: drop-shadow(0px 0px 12px white);
        }

        &.is-active svg {
            fill: cyan;
        }
    }
    .media-controls__progress {
        position: relative;
        display: block;
        height: 1em;
    }
    .media-controls__progress--empty {
        display: none;
    }
    .media-controls__settings {
        border-top: 1px solid gray;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    input[type="range"].slider {
        margin: 0;
        & + output {
            top: 0 !important;
        }
    }
</style>

<div class="media-controls__progress" class:media-controls__progress--empty="{duration == 0}">
    <MediaProgress
        currentTime="{currentTime}"
        duration="{duration}"
        on:timeupdate />
</div>

<div class="media-controls__buttons container">
    <nav class="level is-mobile">
        <div class="level-left">
            <div class="level-item">
                <span class="media-controls__button" on:click="{() => dispatch('search')}">
                    <svg class="icon">
                        <use xlink:href="/sprites.svg#search"/>
                    <svg>
                </span>
            </div>
        </div>
        <div class="level-item">
            <span class="media-controls__button" class:is-active="{repeating}" on:click="{() => dispatch('repeat')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#combine-left-right"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{() => dispatch('previous')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#step-backward"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{() => playing ? dispatch('pause') : dispatch('play')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#{playing ? 'pause' : 'play'}"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{() => dispatch('next')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#step-forward"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{handleVolumeClick}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#{(volume > 0 && !muted) ? 'sound-medium' : 'sound-off'}"/>
                <svg>
            </span>
        </div>
        <div class="left-right">
            <div class="level-item">
                <span class="media-controls__button" on:click="{() => {showSettings = !showSettings;}}">
                    <svg class="icon">
                        <use xlink:href="/sprites.svg#{showSettings ? 'line-angle-down' : 'line-angle-up'}"/>
                    <svg>
                </span>
            </div>
        </div>
    </nav>
</div>

{#if showSettings}
    <div class="media-controls__settings" transition:slide>
        <div class="container">
            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="label">Speed</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control media-controls__playback-rate">
                            <input class="slider is-fullwidth has-output" type="range" min="0.25" max="2" step="0.25" value="{playbackRate}" on:input="{handlePlaybackRateInput}"  on:change="{handlePlaybackRateChange}">
                            <output>{playbackRate}</output>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="label">Offset</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <input class="input" type="number" step="0.1" value="{offset}" on:change="{handleOffsetChange}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}