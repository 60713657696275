<script>
    import { createEventDispatcher, onMount, onDestroy } from 'svelte';
    import Plyr from 'plyr';

    export let type = 'video',
        videoId = '',
        id = null,
        options = {},
        width = null,
        height = null,
        autoplay = null,
        controls = null,
        preload = null;

    let element, player;

    const dispatch = createEventDispatcher();

    onMount(async () => {
        const opts = {
            "controls": [],
            "settings": [],
            "loadSprite": false,
            "autoplay": false,
            "volume": 1,
            "muted": false,
            "clickToPlay": true,
            "disableContextMenu": true,
            "hideControls": true,
            "resetOnEnd": false,
            "displayDuration": false,
            "captions": {
                "active": false,
                "language": "auto",
                "update": false
            },
            "fullScreen": {
                "enabled": false
            },
            "storage": {
                "enabled": false
            },
            "speed": {
                "selected": 1,
                "options": [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
            },
            "loop": {
                "active": false
            },
            "youtube": {
                "noCookie": true,
                "rel": 0,
                "showinfo": 0,
                "iv_load_policy": 3,
                "modestbranding": 1
            },
            ...options
        };
        player = new Plyr(element, opts);
        player.on('ready', event => dispatch('ready', event.detail));
        player.on('error', event => dispatch('error', event.detail));
    });

    onDestroy(() => {
        player.destroy();
    });
</script>

{#if type == 'video'}
    <div class="plyr__video-embed" id="{id}" bind:this="{element}">
        <iframe title="Video player" src="https://www.youtube.com/embed/{videoId}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"></iframe>
    </div>
{:else}
    <audio
        id="{id}"
        width="{width}"
        controls="{controls}"
        bind:this="{element}">
        <slot/>
    </audio>
{/if}