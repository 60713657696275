<script>
    import { createEventDispatcher } from 'svelte';
    import './svg/eye.svg';

    export let index = 0,
        id = '',
        text,
        start,
        end,
        current = false,
        active = false,
        showTimeCode = false,
        segment = [];

    let time, reveal = false;

    $: time = formatTime(start);
    //$: segment = segmentText(text, id);

    const dispatch = createEventDispatcher();

    function formatTime(s) {
        let date = new Date(1000 * s);
        return date.getUTCHours() > 0 ?
            date.toISOString().substr(11, 8) :
            date.toISOString().substr(14, 5);
    }

    function handleTimeClick(event) {
        dispatch('seek', {
            "index": index,
            "id": id,
            "start": start,
            "end": end,
            "current": current,
            "active": active
        });
    }

    function handleRevealClick(event) {
        reveal = !reveal;
    }
</script>

<style type="text/scss">
    $level-colors:
        0 gray,
        1 lightgreen,
        2 lightgreen,
        3 lightblue,
        4 orange,
        5 lightcoral,
        6 lightcoral;

    .caption__container {
        color: silver;
        padding-bottom: 1em;

        .caption__controls {
            visibility: hidden;

            .caption__time {
                cursor: pointer;

                &.caption_time--show {
                    visibility: visible;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .caption__segment {
            text-align: center;

            .caption__word {
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin: 0 0.5ex;

                .caption__word__text {
                    display: block;
                    font-size: 2em;
                    padding-top: 0.5em;
                }

                .caption__word__label {
                    display: block;
                    font-size: 1em;
                }

                .caption__word__label + .caption__word__text {
                    padding-top: 0;
                }

                &[data-tippy-content] {
                    cursor: pointer;
                }
            }
        }

        &.caption--current {
            background: #080808;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
        }

        &.caption--active {
            color: white;
            background: #101010;
        }

        &:hover {
            color: white;
            background: #222;

            .caption__controls {
                visibility: visible;
            }
        }

        .caption__controls__button {
            cursor: pointer;
            svg.icon {
                fill: white;
            }
        }
    }

    @each $i, $c in $level-colors {
        .caption__word[data-level="#{$i}"] {
            color: $c;
        }
        :global(.scroll-captions-hide-level-#{$i}) .caption__word[data-level="#{$i}"] {
            color: transparent;
            border-bottom: 1px dashed $c;
        }
        .caption__word[data-level="#{$i}"]:hover,
        .caption__container[data-show="1"] .caption__word[data-level="#{$i}"],
        .caption--reveal .caption__word[data-level="#{$i}"] {
            color: $c;
        }
    }
</style>

<div class="caption__container" id="{id}" class:caption--current="{current}" class:caption--active="{active}" class:caption--reveal="{reveal}">
    <div class="caption__controls">
        <div class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <span class="caption__controls__button" on:click="{handleRevealClick}">
                        <svg class="icon">
                            <use xlink:href="/sprites.svg#eye"/>
                        <svg>
                    </span>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <time class="caption__time is-family-code" class:caption_time--show="{showTimeCode}" on:click="{handleTimeClick}">{time}</time>
                </div>
            </div>
        </div>
    </div>
    <div class="caption__segment">
        {#each segment as word}
            <span class="caption__word" data-level="{word.level}" data-tippy-content="{word.definition}" id="{word.id}">
                {#if word.label}
                    <span class="caption__word__label">{word.label}</span>
                {/if}
                <span class="caption__word__text">{word.text}</span>
            </span>
        {/each}
    </div>
</div>