<script>
    import { createEventDispatcher, onMount, tick } from 'svelte';
    import Caption from './Caption.svelte';
    import { segmentText } from './zh.js';
    import axios from 'axios';
    import tippy from 'tippy.js';

    export let currentTime = -1, showTimeCodes = false, offset = 0;

    let element, captions = [], origCaptions = [], current = -1, isLoading = false, offsetTime = 0;

    let levelStats = [];

    const dispatch = createEventDispatcher();

    // Change caption start/end times when offset is changed
    $: {
        captions = origCaptions.map(item => {
            return {
                ...item,
                "start": item.start + offset,
                "end": item.end + offset
            };
        });
    }

    $: if (current == -1 || (current > 0 && currentTime <= captions[current-1].end) || (captions.length > 0 && currentTime > captions[current].end)) {
        // Find the first caption that overlaps the given time
        let next = captions.findIndex(
            item => currentTime <= item.end
        );

        if (next != current) {
            current = next;

            // This event can be used to scroll to the current caption
            dispatch('change', {
                "current": (current != -1 ? captions[current] : null)
            });
        }
    }

    export function load(videoId, lang) {

        let options = {
            "params": {
                "v": videoId,
                "lang": lang
            },
            "responseType": "document"
        };

        origCaptions = [];
        isLoading = true;

        axios.get('https://www.youtube.com/api/timedtext', options)
            .then(response => {
                levelStats = [];

                let nodes = Array.from(response.data.documentElement.childNodes);
                let levelCount = {};
                let levelTotal = 0;
                origCaptions = nodes.
                    filter(node => node.textContent.trim() != '').
                    map((node, index) => {
                        let start = parseFloat(node.getAttribute('start'));
                        let dur = parseFloat(node.getAttribute('dur'));
                        let text = node.textContent.trim();
                        let segment = segmentText(text);
                        segment.forEach(w => {
                            if (!levelCount.hasOwnProperty(w.level)) {
                                levelCount[w.level] = 0;
                            }
                            levelCount[w.level]++;
                            levelTotal++;
                        });
                        return {
                            "index": index,
                            "id": 'c'+(index+1),
                            "start": start,
                            "end": start + dur,
                            "text": text,
                            "segment": segment
                        };
                    });

                for (let level in levelCount) {
                    levelStats.push({
                        "level": level,
                        "count": levelCount[level],
                        "value": (100 * levelCount[level] / levelTotal)
                    });
                }
            })
            .catch(event => dispatch('error', event))
            .finally(() => {
                isLoading = false;
            });
    }

    export function getCurrent() {
        if (current != -1) {
            return captions[current];
        }
        return null;
    }

    export function getPrevious() {
        if (current > 0) {
            return captions[current-1];
        } else {
            return null;
        }
    }

    export function getNext() {
        // If the current caption has not started yet
        if (current != -1 && currentTime < captions[current].start) {
            return captions[current];
        // If there exists a next caption
        } else if (current+1 < captions.length) {
            return captions[current+1];
        // If the current caption is the last
        } else {
            return null;
        }
    }

    function handleLevelClick(event) {
        let l = event.target.dataset.level,
            c = event.target.dataset.checked;
        if (c == '1') {
            element.classList.add('scroll-captions-hide-level-'+l);
            event.target.dataset.checked = '0';
        } else {
            element.classList.remove('scroll-captions-hide-level-'+l);
            event.target.dataset.checked = '1';
        }
    }

    onMount(async () => {
        tippy(element, {
            "target": "[data-tippy-content]",
            "arrow": true,
            "theme": "light",
            "size": "large",
            "touch": true,
            "zIndex": 9999
        });
    });
</script>

<style type="text/scss">
    $level-colors:
        0 gray,
        1 green,
        2 lightgreen,
        3 lightblue,
        4 orange,
        5 lightcoral,
        6 red;

    .scroll-captions {
        text-align: center;

        svg.scroll-captions-loader {
            animation: 2s linear infinite loader-animation;
            width: 6em;
            height: 6em;

            circle {
                animation: 1.4s ease-in-out infinite both circle-animation;
                display: block;
                fill: transparent;
                stroke: white;
                stroke-linecap: round;
                stroke-dasharray: 283;
                stroke-dashoffset: 280;
                stroke-width: 10px;
                transform-origin: 50% 50%;
            }

            @keyframes circle-animation {
                0%,
                25% {
                    stroke-dashoffset: 280;
                    transform: rotate(0);
                }
                50%,
                75% {
                    stroke-dashoffset: 75;
                    transform: rotate(45deg);
                }
                100% {
                    stroke-dashoffset: 280;
                    transform: rotate(360deg);
                }
            }
        }

        @keyframes loader-animation {
            0% {
                transform: rotateZ(0deg);
            }
            100% {
                transform: rotateZ(360deg)
            }
        }

        .scroll-captions__stats {
            position: relative;
            display: block;
            height: 1em;
        }
        .scroll-captions__stats__item {
            display: inline-block;
            height: 1em;
            width: 0;
        }

        .scroll-captions__levels {
            margin-top: 1em;
        }
        .scroll-captions__levels .tag {
            cursor: pointer;
            color: white;
            background-color: black;
        }
        @each $i, $bg in $level-colors {
            .scroll-captions__stats__item[data-level="#{$i}"] {
                background-color: $bg;
            }
            .scroll-captions__levels .tag[data-level="#{$i}"][data-checked="1"] {
                background-color: $bg;
            }
        }
    }
</style>

<div class="scroll-captions" bind:this="{element}">
    {#if isLoading}
        <svg class="scroll-captions-loader" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45"/>
        </svg>
    {/if}
    {#if levelStats}
        <div class="section">
            <div class="scroll-captions__stats">
                {#each levelStats as stat}
                    <div class="scroll-captions__stats__item" data-tippy-content="{stat.level > 0 ? 'HSK'+stat.level : 'Unknown'}: {stat.value.toFixed(1)}%" data-level="{stat.level}" style="width: {stat.value}%"></div>
                {/each}
            </div>
            <div class="scroll-captions__levels">
                <div class="tags">
                    {#each levelStats as stat}
                        <span class="tag" data-level="{stat.level}" data-checked="1" data-tippy-content="{stat.value.toFixed(1)}%" on:click="{handleLevelClick}">
                            {stat.level > 0 ? 'HSK'+stat.level : 'Unknown'}
                        </span>
                    {/each}
                </div>
            </div>
        </div>
    {/if}
    {#each captions as caption}
        <Caption {...caption}
            current="{current == caption.index}"
            active="{caption.start <= currentTime && currentTime <= caption.end}"
            showTimeCode="{showTimeCodes}" on:seek /> 
    {/each}
</div>