<script>
	import { onMount } from 'svelte';
	import Bulma from './Bulma.svelte';
	import SearchBar from './SearchBar.svelte';
	import MediaList from './MediaList.svelte';
	import Plyr from './Plyr.svelte';
	import LanguageSelect from './LanguageSelect.svelte';
	import ScrollCaptions from './ScrollCaptions.svelte';
	import MediaControls from './MediaControls.svelte';
	import MediaProgress from './MediaProgress.svelte';
	import { notify } from './Notify.svelte';

	const preferredLang = ['zh-CN', 'zh-Hans', 'zh-Hant', 'zh-HK', 'zh-TW', 'zh-MO', 'zh-SG', 'zh'];

	let currentTime = 0,
		duration = 0,
		playbackRate = 1,
		offset = 0,
		volume = 100,
		muted = false,
		playing = false,
		repeating = null;

	let videoId = null, lang = null;

	let ignoreTimeUpdate = false;

	// MediaElement variables

	let mediaPlayer, mediaContainer = null;

	// LanguageSelect variables

	let languageSelect;

	// ScrollCaptions variables

	let scrollCaptions, autoScroll = true, autoScrollTimeout = null, autoScrollTimeoutDelay = 4000;

	// MediaControls variables

	let mediaControls;

	// Other

	const isFirefox = (navigator.userAgent.toLowerCase().indexOf('firefox') > -1);

	function getYoutubeVideoId(url) {
		let re = /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_\-]+)/i;
		let m = re.exec(url);
		return m && m[1];
	}

	// SearchBar events ------------

	// When user submits a search query
	function handleSearch(event) {
		let url = event.detail.q;
		videoId = getYoutubeVideoId(url);
	}

	// LanguageSelect events ------------

	// When the language select has downloaded the list of languages
	function handleLanguageReady(event) {
		lang = preferredLang.find(code => {
			return event.detail.langOptions.findIndex(l => l.code == code) != -1;
		});
		if (lang) {
			console.log('Found preferred language: ', lang);
			scrollCaptions.load(videoId, lang);
		}
	}

	// When user selects a language
	function handleLanguageChange(event) {
		lang = event.detail.lang;
		scrollCaptions.load(videoId, lang);
	}

	// MediaList events ------------

	function handleMediaSelect(event) {
		videoId = event.detail.videoId;
	}

	// MediaElement events ------------

	// When the mediaelement is initialized
	function handleMediaElementReady(event) {

		// Retrieve the mediaelement player instance
		mediaPlayer = event.detail.plyr;

		duration = event.detail.plyr.duration;
		mediaPlayer.on('loadedmetadata',
			event => {
				duration = event.detail.plyr.duration;
			}
		);

		// Update current caption on mediaelement events
		mediaPlayer.on('timeupdate',
			event => {
				if (!ignoreTimeUpdate) {
					currentTime = event.detail.plyr.currentTime;
				}
				if (repeating != null && currentTime > repeating.end) {
					updateCurrentTime(repeating.start, false);
				}
			});

		['play', 'playing'].forEach(type => {
			mediaPlayer.on(type,
				event => {
					ignoreTimeUpdate = false;
					playing = true;
				});
		});

		['pause', 'ended'].forEach(type => {
			mediaPlayer.on(type,
				event => {
					ignoreTimeUpdate = true;
					playing = false;
				});
		});

		mediaPlayer.on('ratechange',
			event => {
				playbackRate = event.detail.plyr.speed;
			});

		mediaPlayer.on('volumechange',
			event => {
				volume = event.detail.plyr.volume;
				muted = event.detail.plyr.muted;
			});

		playbackRate = mediaPlayer.speed;
		volume = mediaPlayer.volume;
		muted = mediaPlayer.muted;
	}

	function updateCurrentTime(time, forceAutoScroll = true) {
		ignoreTimeUpdate = true;
		mediaPlayer.currentTime = time;
		currentTime = time;
		if (forceAutoScroll) {
			autoScroll = true;
			scrollToCaption(scrollCaptions.getCurrent());
		}
	}

	// ScrollCaptions events ------------

	function scrollToCaption(current) {
		if (current == null) return;
		// Get the HTML element of the caption
		let el = document.getElementById(current.id);

		if (el) {
			let elRect = el.getBoundingClientRect();
			let mRect = mediaContainer.getBoundingClientRect();
			let y = document.documentElement.scrollTop + elRect.top;

			if (elRect.left + 1 <= mRect.right && mRect.left + 1 <= elRect.right) {
				y -= mRect.height;
			}

			window.scrollTo({
				"top": y,
				"behavior": (isFirefox ? "auto" : "smooth") // Bugzilla #1139745 in Firefox, bug when using smooth scroll
			});
		}
	}

	// When the current caption has changed
	function handleCaptionChange(event) {
		if (autoScroll && event.detail.current != null) {
			scrollToCaption(event.detail.current);
		}
	}

	// When the user has clicked on a time code of a caption
	function handleCaptionSeek(event) {
		if (repeating != null) {
			repeating = event.detail;
		}
		updateCurrentTime(event.detail.start);
	}

	// MediaControls events ------------

	// When the user clicked on play button
	function handleControlPlay() {
		autoScroll = true;
		scrollToCaption(scrollCaptions.getCurrent());
		mediaPlayer.play();
	}

	// When the user clicked on pause button
	function handleControlPause() {
		mediaPlayer.pause();
	}

	// When the user clicked on previous button
	function handleControlPrevious() {
		let caption = scrollCaptions.getPrevious();
		if (caption != null) {
			if (repeating != null) {
				repeating = caption;
			}
			updateCurrentTime(caption.start);
		}
	}

	// When the user clicked on next button
	function handleControlNext() {
		let caption = scrollCaptions.getNext();
		if (caption != null) {
			if (repeating != null) {
				repeating = caption;
			}
			updateCurrentTime(caption.start);
		}
	}

	// When the user clicked on repeat button
	function handleControlRepeat() {
		if (repeating == null) {
			repeating = scrollCaptions.getCurrent();
		} else {
			repeating = null;
		}
	}

	// When the user clicked on the progress bar
	function handleControlTimeUpdate(event) {
		updateCurrentTime(event.detail.time);
	}

	function handleControlRateChange(event) {
		playbackRate = event.detail.playbackRate;
		if (mediaPlayer) {
			mediaPlayer.speed = event.detail.playbackRate;
		}
	}

	function handleControlOffsetChange(event) {
		offset = event.detail.offset;
	}

	// When the user changed the volume
	function handleControlVolumeChange(event) {
		if (mediaPlayer) {
			mediaPlayer.volume = event.detail.volume;
			mediaPlayer.muted = event.detail.muted;
		}
	}

	// General events ------------

	function handleError(event) {
		console.error(event.detail);
		notify({
			"message": event.detail.message,
			"type": "is-danger",
			"closable": false,
			"closeOnClick": true
		});
	}

	onMount(() => {
		// Disable auto-scroll if the user scrolls manually
		['wheel', 'touchmove'].forEach(type => {
			window.addEventListener(type,
				event => {
					autoScroll = false;
					if (autoScrollTimeout) {
						clearTimeout(autoScrollTimeout);
					}
					autoScrollTimeout = setTimeout(() => {
						autoScrollTimeout = null;
						autoScroll = true;
						if (playing) {
							scrollToCaption(scrollCaptions.getCurrent());
						}
					}, autoScrollTimeoutDelay);
				});
		});
	});
</script>

<style type="text/scss">
	@charset "utf-8";
	@import "bulma/sass/utilities/mixins.sass";
	.media-element {
		position: sticky;
		top: 0;
		max-width: 100vh;
		overflow: hidden;
		margin: auto;
		text-align: center;
		z-index: 10;

		/* Fix conflict with bulma */
		:global(iframe) {
			height: 100%;
		}
	}
	@include mobile {
		.scroll-captions {
			padding-left: 0;
			padding-right: 0;
		}
	}

	/* Landscape mode */
	@media (min-aspect-ratio: 1/1) {
		.media-element {
			position: sticky;
			float: left;
			top: 0;
			left: 0;
			width: 50%;
			max-width: 150vh;
			margin-left: 0;
		}
		.language-select {
			margin-left: 50%;
		}
		.scroll-captions {
			margin-left: 50%;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.media-controls {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		background-color: black;
		border-top: 1px solid gray;
	}
	// tippy light theme
    .tippy-tooltip {
        $text-color: #26323d;
        $background-color: white;
        :global(&.light-theme) {
            color: $text-color;
            background-color: $background-color;
        }
        :global(&.light-theme[data-animatefill]) {
            background-color: transparent;
        }
        :global(&.light-theme .tippy-backdrop) {
            background-color: $background-color;
        }
        :global(&.light-theme[x-placement^='top'] .tippy-arrow) {
            border-top-color: $background-color;
        }
        :global(&.light-theme[x-placement^='bottom'] .tippy-arrow) {
            border-bottom-color: $background-color;
        }
        :global(&.light-theme[x-placement^='left'] .tippy-arrow) {
            border-left-color: $background-color;
        }
        :global(&.light-theme[x-placement^='right'] .tippy-arrow) {
            border-right-color: $background-color;
        }
        :global(&.light-theme .tippy-roundarrow) {
            fill: $background-color;
        }
    }
</style>

<section class="section">
	<div class="container">
		<SearchBar on:search="{handleSearch}"/>
	</div>
</section>

{#if videoId}
	<div class="media-element" bind:this="{mediaContainer}">
		<Plyr
			type="video"
			videoId="{videoId}"
			on:ready="{handleMediaElementReady}"
			on:error="{handleError}"/>
	</div>

	<section class="section">
		<div class="container">
			<a href="https://www.youtube.com/watch?v={videoId}">Youtube link</a>
		</div>
	</section>

	<section class="section language-select">
		<div class="container">
			<LanguageSelect bind:this="{languageSelect}"
				videoId="{videoId}"
				lang="{lang}"
				on:ready="{handleLanguageReady}"
				on:change="{handleLanguageChange}"
				on:error="{handleError}"/>
		</div>
	</section>

	<section class="section scroll-captions">
		<div class="container">
			<ScrollCaptions bind:this="{scrollCaptions}"
				currentTime="{currentTime}"
				showTimeCodes="{!autoScroll}"
				offset="{offset}"
				on:change="{handleCaptionChange}"
				on:seek="{handleCaptionSeek}"
				on:error="{handleError}"/>
		</div>
	</section>
{:else}
	<section class="section">
		<div class="container">
			<MediaList on:select="{handleMediaSelect}"/>
		</div>
	</section>
{/if}

{#if mediaPlayer}
	<div class="media-controls">
		<MediaControls bind:this="{mediaControls}"
			currentTime="{currentTime}"
			duration="{duration}"
			playbackRate="{playbackRate}"
			volume="{volume}"
			muted="{muted}"
			playing="{playing}"
			repeating="{repeating != null}"
			on:play="{handleControlPlay}"
			on:pause="{handleControlPause}"
			on:previous="{handleControlPrevious}"
			on:next="{handleControlNext}"
			on:repeat="{handleControlRepeat}"
			on:volumechange="{handleControlVolumeChange}"
			on:timeupdate="{handleControlTimeUpdate}"
			on:ratechange="{handleControlRateChange}"
			on:offsetchange="{handleControlOffsetChange}"
			on:search="{() => {mediaPlayer = null; videoId = null}}"/>
	</div>
{/if}

<div class="footer">
	<div class="container"></div>
</div>